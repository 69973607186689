.ordersPage{
    width: 100%;
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}


.orderStatus__buttons{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin-bottom: 40px;
}
.orderStatus__button{
    padding: 15px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    background: #fff;
    border-radius: 4px;
    width: 100%;
    height: 52px;
    cursor: pointer;
}
.orderStatus__button:hover{
    border: 1px solid #E52EC7;
}
.orderStatus__buttonSelected{
    background: transparent;
    border: 1px solid #E52EC7;
}
.orderStatus__buttonAll{
    background: #E0ECF6;
}
.orderStatus__button .orderStatus__buttonIcon {
    color: #48A5D1;
}

.orderStatus__buttonSelected .orderStatus__buttonIcon{
    color: #E52EC7;
}
.orderStatus__buttonText{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #5E5E5E;
    width: 166px;
    margin: 0 0 0 14px;
}
.orderStatus__buttonNum{
    position: absolute;
    right: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    color: #48A5D1;
}


.homePage__orderTableBox{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 40px;
}
.orderTableBox__title{
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 35px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 20px;
}
.orderTableBox__filterBox{
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.MuiDataGrid-columnHeaders{
    background: rgba(72, 165, 209, 0.3);
}
.orderTableBox__deleteButton{
    background: #FF8080 !important;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 1px 5px rgba(0, 0, 0, 0.12) !important;
    width: 100px;
    height: 36px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: #FFFFFF !important;
    cursor: pointer;
    margin-right: 20px !important;
}

.billingPaymentInfo div{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
}