.authPage{
    width: 100%;
    height: 100vh;
}
.authModal{
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-content: center;
    background-image: url("../assets/images/Cover_2  1.png");
}
.authModal__mainContainer{
    height: 100%;
    width: 100%;
    padding: 0 62px;
    display: flex;
    align-items: center;
    background: transparent;
}
.authModal__form{
    background: #FFFFFF;
    border-radius: 10px;
    max-width: 608px;
    width: 100%;
    height: 471px;
    padding: 0 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 500px) {
    .authModal__mainContainer{
        padding: 0 20px;
    }
    .authModal__form{
        padding: 0 20px;
    }
}
.authModal-form__title{
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 7px;
}
.Avatar{
    background: #48A5D1 !important;
    margin-bottom: 14px;
}