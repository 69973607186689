.courierHeader{
    width: 100%;
    height: 80px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9999;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    box-shadow: 4px 0 20px rgba(0, 0, 0, 0.1);
}

.courierMainPage{
    width: 100%;
    min-height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
}

.courier_delivery{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 80px;
    background: #FFFFFF;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
    gap: 10px;
}
.courier_delivery div{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.courier_delivery .user_id{
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
}
.courier_delivery .user_name{
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
}
.courier_delivery .date{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
}
.courier_delivery .status{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #48A5D1;
}

.courierDelivery{
    width: 100%;
    min-height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
}
.courierDelivery__title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    margin-bottom: 20px;
    border-bottom: 2px solid #D9D9D9;
}
.courierDelivery__title p{
    font-weight: 600;
    font-size: 16px;
    line-height: 116%;
    color: rgba(0, 0, 0, 0.87);
    white-space: nowrap;
}

.courierDelivery__body{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 40px;
}
.courierDelivery__body div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}
.courierDelivery__body div p{
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #48A5D1;
}
.courierDelivery__body div span{
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #000000;
}


.MArrangeDelivery-orders__orderBox{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: #FFFFFF;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 10px;
}
.MArrangeDelivery-orderBox__textBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.MArrangeDelivery-orderBox__textBox p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 5px;
}
.MArrangeDelivery-orderBox__textBox div{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.MArrangeDelivery-orderBox__textBox span:first-child{
    margin-right: 21px;
}
.MArrangeDelivery-orderBox__textBox span{
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #48A5D1;
}

.emptyData{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.emptyData p{
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.02em;
    color: rgba(0, 0, 0, 0.3);
}