.customers{
    width: 100%;
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.homePage__filterBox{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.tableColumnHeader{
    background: rgba(72, 165, 209, 0.47);
}
