.warehouse{
    width: 100%;
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.warehouse_excelImportInput{
    margin-top: 16px;
    margin-bottom: 8px;
    height: 56px !important;
    justify-content: space-between;
    text-transform: none;
    color: rgba(0, 0, 0, 0.54);
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.54);
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 16.5px 14px;
}