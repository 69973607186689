.notifications{
    width: 100%;
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.notifications__titleButtonBox{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.notificationPage__mainContainer{
    width: 100%;
}
.notification__deleteButton{
    background: #FF8080 !important;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%), 0 1px 5px rgb(0 0 0 / 12%) !important;
    width: 100px;
    height: 36px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: #FFFFFF !important;
    cursor: pointer;
    margin-right: 20px !important;
}
.notificationsView__wrapper{
    width: 100%;
    height: 470px;
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 32px 59px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}
.notificationsView__wrapperLoading{
    width: 100%;
    height: 470px;
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 32px 59px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.notificationsView__wrapperTitleBar{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
}
.notificationsView__title{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #000000;
}
.notificationsView__date{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    letter-spacing: -0.02em;
    color: #8F8F8F;
}
.notificationsView__wrapperTextBar{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #8F8F8F;
}