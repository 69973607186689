.componentMainWrapper__title p{
    margin-left: 20px;
}
.homePage{
    width: 100%;
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.homePage__mainContainer{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}
.homePage__card{
    min-height: 224px;
    background: #FFFFFF;
    border: 1px solid #48A5D1;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.homePage__ordersStatisticBox{
    grid-column-start: 1;
    grid-column-end: 3;
}
.homePage-card_info{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-right: 30px;
}
.homePage__ordersStatisticBox .homePage-card_info{
    justify-content: flex-start;
}
.homePage-card__buttons{
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 30px;
    grid-row-gap: 20px;
}
.homePage-card__button{
    width: 159px;
    border: 1px solid #48A5D1;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #48A5D1;
    cursor: pointer;
}
.homePage-card__buttonSelected{
    background: #48A5D1;
    color: #FFFFFF;
}
.homePage-card_infoTitle{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 20px;
}
.homePage__ordersStatisticBox .homePage-card_infoTitle{
    margin-bottom: 13px;
}
.homePage-card_infoText{
    height: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 47px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    letter-spacing: -0.02em;
    color: #000000;
}
.homePage-card_infoButtonBox{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.homePage-card_infoButtonBox p{
    margin-right: 9px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #009D35;
    border: 1px solid #009D35;
    border-radius: 30px;
    height: 30px;
    min-width: 117px;
    white-space: nowrap;
}
.homePage-card_infoStatistics{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 6px;
}
.homePage-card_infoStatistic{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.homePage-card_infoStatistic_title{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.02em;
    color: rgba(0, 0, 0, 0.36);
}
.homePage-card_infoStatisticFlagBox{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.homePage-card_infoStatisticFlagBox img{
    margin-right: 10px;
    height: 30px;
}
.homePage-card_infoStatisticBox{
    width: 100%;
    height: 30px;
    background: rgba(72, 165, 209, 0.6);
    border-radius: 4px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.homePage-card_infoStatisticBox__text{
    position: absolute;
    left: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.homePage-card_infoStatisticBox__text p{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
}
.homePage-card_infoStatisticBox__text span{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
}
.homePage-card_infoStatisticBox_rangeBox{
    height: 100%;
    border-radius: 4px;
    background: radial-gradient(100% 559.83% at 0% 0%, #48A5D1 0%, #006595 100%)
}


.homePage__mainContainerNoAdmin{
    width: 100%;
    height: 600px;
    background-image: url("../assets/images/Cover_2  1.png");
    background-position: center;
    background-size: cover;
}