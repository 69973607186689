.tariffEdit{
    width: 100%;
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.tariffEdit__mainContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: white;
    padding: 30px;
}
.tariffEdit__addressBox, .tariffEdit__tariffInfoBox{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: white;
}
.tariffEdit__addressBox h1{
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: #000000;
}
.tariffEdit__wrappers{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    padding-bottom: 30px;
    padding-top: 30px;
}
.tariffEdit__addressBox{
    border-bottom: 2px solid rgba(72, 165, 209, 0.5);
}
.tariffEdit__wrappers div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.tariffEdit__wrappers p{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.15px;
    color: #000000;
}
.componentMainWrapper__title span{
    color: #48a5d1;
    margin-left: 20px;
    text-transform: uppercase;
}