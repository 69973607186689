.queueUserPanel{
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
}
.queueUserPanel__header{
    width: 100%;
    height: 70px;
    background: linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%);
    box-shadow: 4px 4px 4px rgba(25, 118, 210, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}
.queueUserPanel__footer{
    width: 100%;
    height: 50px;
    background: linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%);
    box-shadow: 4px 4px 4px rgba(25, 118, 210, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}
.queueUserPanel__body{
    width: 100%;
    /* height: calc(100vh - 120px); */
    height: 100dvh;
    background-image: url("../assets/images/Cover_7 1.png");
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 100px;
}
.queueUserPanel__body h1{
    margin-bottom: 60px;
    font-size: 80px;
}
.queueUserPanel__body div *{
    font-size: 40px;
}
.queueUserPanel .MuiDataGrid-columnHeaders{
    max-height: 100px !important;
    min-height: 100px !important;
    line-height: 100px !important;
    position: static !important;
}