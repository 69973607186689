.arrangeDelivery{
    width: 100%;
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.arrangeDelivery__mainWrapper{
    width: 1300px;
    padding: 40px 0 40px 120px;
}
.arrangeDelivery__title{
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 35px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 10px;
}
.arrangeDelivery__subTitle{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #8F8F8F;
    width: 550px;
    margin-bottom: 28px;
}
.arrangeDelivery__mainContainer{
    width: 100%;
    background: white;
    padding: 20px 20px 50px 20px;
    border-radius: 10px;
}
.arrangeDelivery-mainContainer__title{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 15px;
}
.arrangeDelivery__selectBox{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.arrangeDelivery-selectBox__title{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 15px;
}