.layout{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: #F5F5F5;
}
.componentMainWrapper{
    width: 1300px;
    padding: 100px 0 40px 120px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.componentMainWrapper__title{
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 35px;
    letter-spacing: -0.02em;
    color: #000000;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 50px 0;
}
.componentMainWrapper__subTitle{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #8F8F8F;
    margin-bottom: 44px;
}
.componentMainWrapper__container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.newDataAdd{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px 5px 0 0;
}
.newDataAdd__title{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 20px;
    background: rgba(72, 165, 209, 0.7);
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px 5px 0 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #FFFFFF;
}
.newDataAdd__form{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 20px;
    padding: 40px 20px 60px 20px;
}
.newDataAdd__textFieldBox{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.newDataAdd__textFieldBox > p{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.15px;
    color: #000000;
}

.newDataAdd__textFieldBox .ql-editor{
    min-height: 160px;
}

.defaultButton{
    background: linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%) !important;
    height: 36px !important;
    padding: 0 20px !important;
    min-width: 220px !important;
    text-transform: uppercase !important;
    color: white !important;
}

.MuiDataGrid-columnHeaders{
    background: rgba(72, 165, 209, 0.3);
}
.MuiDataGrid-root{
    border-radius: 10px !important;
}





.layoutCourier{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #F5F5F5;
    padding: 80px 0 0 0;
}