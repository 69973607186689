.header *{
    transition-duration: .2s;
}
.header{
    width: 100%;
    height: 70px;
    background: linear-gradient(100.58deg, #48A5D1 0%, #E52EC7 100%);
    box-shadow: 4px 4px 4px rgba(25, 118, 210, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 998;
}
.header__mainWrapper{
    width: 1300px;
    height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header__logo{
    margin-right: auto;
}
.header__navLinks{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-right: 2px solid #D9D9D9;
    height: 100%;
    white-space: nowrap;
    width: 563px;
    padding-right: 20px;
    margin-right: 20px;
}
.header__navLink{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    text-decoration: none;
    padding: 2px;
}
.header__navLink:hover{
    font-weight: 700;
    border-bottom: 1px solid white;
}
.header__navLinkSelected{
    font-weight: 700;
    border-bottom: 1px solid white;
}
.header__exchangeText{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.17px;
    color: #FFFFFF;
    text-align: center;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
}
.header__exchangeText p{
    white-space: nowrap;
}
.header__scoreText{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.15px;
    color: #FFFFFF;
    height: 100%;
    background: #EF5DA8;
    border-radius: 5px;
    padding: 3px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.header__scoreText span{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    text-align: center;
    letter-spacing: 0.17px;
    color: #FFFFFF;
}
.header__notificationIcon{
    margin: 0 20px;
}
.header__avatarBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header-avatarBox__text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}
.header-avatarBox__text p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: right;
    letter-spacing: 0.15px;
    color: #FFFFFF;
    white-space: nowrap;
}
.header-avatarBox__text span{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    text-align: right;
    letter-spacing: 0.17px;
    color: #FFFFFF;
    opacity: 0.6;
    white-space: nowrap;
}



.header__sideBarOpened{
    position: fixed;
    left: 0;
    top: 70px;
    height: calc(100% - 126px);
    width: 217px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 40px 0 130px 0;
}
.header-sideBarLink{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-left: 34px;
    align-items: center;
    height: 40px !important;
    margin-bottom: 20px;
}
.header-sideBarLink .header-sideBarLinkText{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #000000;
    margin-left: 10px;
    white-space: nowrap;
}
.header-sideBarLink .header-sideBarLinkIcon{
    color: #B9B9B9;
    height: 40px;
}

.header-sideBarLinkSelected{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-left: 34px;
    align-items: center;
    height: 40px;
    margin-bottom: 20px;
    background: rgba(72, 165, 209, 0.3);
    border-right: 4px solid #48A5D1;
}
.header-sideBarLinkSelected .header-sideBarLinkText{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #48A5D1;
    margin-left: 10px;
    white-space: nowrap;
}
.header-sideBarLinkSelected .header-sideBarLinkIcon{
    color: #48A5D1;
    height: 40px;
}



.header-sideBarLink:hover{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-left: 34px;
    align-items: center;
    height: 40px;
    margin-bottom: 20px;
    background: rgba(72, 165, 209, 0.3);
    border-right: 4px solid #48A5D1;
}
.header-sideBarLink:hover .header-sideBarLinkText{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #48A5D1;
    margin-left: 10px;
    white-space: nowrap;
}
.header-sideBarLink:hover .header-sideBarLinkIcon{
    color: #48A5D1;
}


.header__sideBarClosed{
    position: fixed;
    left: 0;
    top: 70px;
    height: calc(100% - 126px);
    width: 80px;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 40px 0 130px 0;
}

.header__sideBarClosed .header-sideBarLink{
    padding-left: 27px;
}
.header__sideBarClosed .header-sideBarLinkSelected{
    padding-left: 27px;
}
.header__sideBarClosed .header-sideBarLinkText{
    display: none;
}
.header__searchInput{
    width: 310px;
    height: 32px;
    background: white;
    margin-right:25px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 4px;
    display: flex;
    justify-content: start;
    align-items: center;
}
.header__searchInput input{
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: rgba(0, 0, 0, 0.83);
}
.header-sideBarLinkText{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
}
.header-sideBarLinkText span{
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 9px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    text-decoration-line: underline;
    color: #48A5D1;
}
.header-sideBar__currencyBox{
    position: absolute;
    width: 100%;
    bottom: 0;
    background: #54afda;
    padding-bottom: 10px;
    padding-top: 10px;
}
.header-sideBar__currencyBox .header-sideBarLink{
    margin-bottom: 10px;
}
.header-sideBar__currencyBox .header-sideBarLink:hover{
    background: none;
    border-right: unset;
}
.header-sideBar__currencyBox .header-sideBarLink:hover .header-sideBarLinkText{
    color: black;
}
.header-sideBar__currencyBox .header-sideBarLink:hover .header-sideBarLinkIcon{
    color: #B9B9B9;
}
.header-sideBar__currencyBox .header-sideBarLink:hover .header-sideBarLinkText span{
    cursor: pointer;
}
.headerSideBar__currencyBoxCurrencyBox{
    width: 100%;
    display: none;
    justify-content: space-between;
    align-items: center;
    padding-left: 27px;
    padding-right: 36px;
}
.headerSideBar__currencyBoxCurrencyBox div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.headerSideBar__currencyBoxCurrencyBox p{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: rgba(0, 0, 0, 0.36);
}
.headerSideBar__currencyBoxCurrencyBox span{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.02em;
    color: rgba(0, 0, 0, 0.7);
}
.header__sideBarOpened .headerSideBar__currencyBoxCurrencyBox{
    padding-left: 34px;
    display: flex;
}


.header-currencyBox__avatar{
    width: 40px;
    height: 40px;
    background: rgba(72, 165, 209, 0.5);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.header-currencyBox__title{
    margin: 13px 0 30px 0;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #000000;
}

.header__sideBarWrapper{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
}
.header__sideBarWrapper::-webkit-scrollbar {
    display: none;
}