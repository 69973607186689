.orderViewPage{
    width: 100%;
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.orderViewPage__mainContainer{
    width: 100%;
    display: grid;
    grid-template-columns: 310px auto;
    grid-column-gap: 20px;
}
.orderViewPage__orderInfoBox{
    min-width: 310px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 20px;
}
.orderViewPage__orderInfoItem{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 15px;
    overflow: hidden;
}
.orderViewPage__orderInfoItem h1{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.02em;
    color: #48A5D1;
}
.orderViewPage__orderInfoItem div{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.orderViewPage__orderInfoItem p{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 235.19%;
    letter-spacing: -0.02em;
    color: #000000;
}


.orderViewPage__orderProductsBox{
    width: auto;
}


